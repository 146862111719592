import React, { useEffect, useRef, useState } from 'react';
import './home.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import PieChart from './piechart.js';
import MobilePage from './mobile';

import logo from '../img/logo.png'; 
import mapImage from '../img/map.png'; 
import brandmapImage from '../img/brand-map.png'; 
import heroimg from '../img/design2.jpg'; 

import historyImage from '../img/history.png';
import turnaroundImage from '../img/turnaround.png'
import materialmatrixImage from '../img/material-matrix.png';
import businessmodelImage from '../img/business-models.png';
import productmatrixImage from '../img/Product-matrix.png';
import performancedashboardImage from '../img/performance-dashboard.png';
import practicesImage from '../img/practices.png'
import teamImage from '../img/team.png'
import heroimg1 from '../img/intro1.JPG';
import heroimg2 from '../img/intro2.jpg';
import heroimg3 from '../img/intro3.jpg';
import heroimg4 from '../img/intro4.jpg';
import heroimg5 from '../img/intro5.JPG';
import Navbar from './navbar';


const isMobileDevice = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return true;
  }

  if (/android/i.test(userAgent)) {
    return true;
  }

  if (/Mobi/.test(userAgent)) {
    return true;
  }

  return false;
};


function Homepage() {
  const navigate = useNavigate();
  const pieChartsRef = useRef(null);
  const [isPieChartsVisible, setPieChartsVisible] = useState(false);
  const [currentHeroIndex, setCurrentHeroIndex] = useState(0);
  const heroImages = [heroimg1, heroimg2, heroimg3, heroimg4, heroimg5];

  useEffect(() => {
    if (isMobileDevice()) {
      navigate('/mobile'); 
    }
  }, [navigate]); 

  useEffect(() => {
    const cycleImages = () => {
      setCurrentHeroIndex((prevIndex) => (prevIndex + 1) % heroImages.length);
    };

    const intervalId = setInterval(cycleImages, 5000);

    return () => clearInterval(intervalId); 
  }, []);

  useEffect(() => {
    const highlightCurrentPage = () => {
      const links = document.querySelectorAll('nav ul li a');
      links.forEach(link => {
        if (link.getAttribute('href') === window.location.hash) {
          link.classList.add('current-page');
        } else {
          link.classList.remove('current-page');
        }
      });
    };

    const handleScroll = () => {
      const keywordContainer = document.querySelector('.keyword-section');
      const keywords = document.querySelectorAll('.keyword');
      if (keywordContainer) { 
        const containerPosition = keywordContainer.getBoundingClientRect().top;
        const screenPosition = window.innerHeight / 1.3;
    
        if (containerPosition < screenPosition) {
          keywords.forEach((keyword, index) => {
            setTimeout(() => {
              keyword.classList.add('visible');
            }, index * 200);
          });
        }
      }
    };
    

    const handlePieChartIntersection = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          console.log('Pie Charts are in view');
          setPieChartsVisible(true);
        }
      });
    };

    const observer = new IntersectionObserver(handlePieChartIntersection, {
      threshold: 0.5,
    });

    if (pieChartsRef.current) {
      observer.observe(pieChartsRef.current);
    }

    window.addEventListener('hashchange', highlightCurrentPage);
    window.addEventListener('scroll', handleScroll);
    highlightCurrentPage(); 

    return () => {
      window.removeEventListener('hashchange', highlightCurrentPage);
      window.removeEventListener('scroll', handleScroll);
      if (pieChartsRef.current) {
        observer.unobserve(pieChartsRef.current);
      }
    };
  }, []);
  

  

  return (
    <div>
      <Navbar/>
      <main>
        <section className="hero-container" id="top">
          <div className="hero-image-text-container">
            <img
              src={heroImages[currentHeroIndex]}
              alt="hero"
              className="hero-image"
            />
            <div className="overlay-logo-container">
              <img src={logo} alt="Logo" className="overlay-logo" />
            </div>
            <div className="overlay-text">
              CRAFTING FASHION, DELIVERING PERFECTION
            </div>
          </div>
        </section>

        <section className="keyword-section">
          <div className="keyword-text">
            <div className="keyword">
              Scotic Designs has <span style={{ color: '#00b0f0', fontSize: '2em' }}>long history</span> of<br />
              expertise in specialty manufacturing and product sourcing<br />
              throughout Asia since 2002.
            </div>
            <div className="keyword">
              We adapt to evolving trends by offering a wide range of <span style={{ color: '#00b0f0', fontSize: '2em' }}>fabric,</span> <br />
              <span style={{ color: '#00b0f0', fontSize: '2em' }}>innovation</span> and <span style={{ color: '#00b0f0', fontSize: '2em' }}>product designs</span> to meet brand <br />
              expectations.
            </div>
            <div className="keyword">
              From <span style={{ color: '#00b0f0', fontSize: '2em' }}>classic to modern styles,</span> our sophisticated designs<br />
              and advanced techniques cater to diverse fabrics and fashion <br />preferences.
            </div>
          </div>
          <div className="keyword-image">
            <img src={historyImage} alt="Stitch" />
          </div>
        </section>



        <h2 className = "business-model-title" id="concepttocollection">Concept to Collection</h2>

        <section className="turnaround-section">

        
         
          <div className="turnaround-content">
            <img src={turnaroundImage} alt="Turnaround" className="turnaround-image" />
            <div className="turnaround-text">
              <h2>Leading to much FASTER decision making*</h2>
              <div className="lead-time-container">
                <span className="lead-time-label">Average concept sample lead-time:</span>
                <span className="lead-time-value">5 hours</span>
              </div>
            </div>
          </div>
        </section>

          <h2 className = "business-model-title" id="businessmodels">Core Business Models</h2>

        <section className="business-model">
          <div className="business-model-container">
            <img src={businessmodelImage} alt="Business-model" className="business-model-image" />
          </div>
        </section>

        <h2 className = "business-model-title" id="productoverview">Material and Product Overview</h2>

        <section className="material-matrix">
          <div className="material-matrix-container">
            <img src={materialmatrixImage} alt="Material Matrix" className="material-matrix-image" />
          </div>
        </section>

        <h2 className = "business-model-title" id="productmatrix">Product Matrix</h2>

        <section className="product-matrix">
          <div className="product-matrix-container">
            <img src={productmatrixImage} alt="Product Matrix" className="product-matrix-image" />
          </div>
        </section>

        <h2 className = "business-model-title" id="performance">Performance Dashboard</h2>

        <section className="performance-dashboard">
          <div className="performance-dashboard-container">
            <img src={performancedashboardImage} alt="performance-dashboard" className="performance-dashboard-image" />
          </div>
        </section>

        <h2 className = "business-model-title" id="certifications">Sustainable Practices & Certifications</h2>

        <section className="practices">
          <div className="practices-container">
            <img src={practicesImage} alt="practices" className="practices-image" />
          </div>
        </section>

        <h2 className = "business-model-title" id="ourteam">Who's making it happen</h2>

        <section className="team">
          <div className="team-container">
            <img src={teamImage} alt="team" className="team-image" />
          </div>
        </section>

        <h2 className = "business-model-title" id="businessmap">Business World Map</h2>

        <section className="map">
          <div className="map-container">
            <img src={mapImage} alt="map" className="map-image" />
          </div>
        </section>

        <h2 className = "business-model-title" id="brandmap">Brand Map</h2>

        <section className="brand-map">
          <div className="brand-image-container">
            <img src={brandmapImage} alt="Brand Map" className="brand-map-image" />
          </div>
        </section>
      </main>

      <footer>
        <div className="footer-container">
          <div className="footer-section">
            <h3>India</h3>
            <p>Scotic Designs Pvt. Ltd.<br />
            No.A-4,2nd Floor, NGEF,<br />
            Ancillary Industrial Estate,<br />
            (Techomark Television Building)<br />
            Mahadevapura, Bangalore<br />
            560048 India<br />
            <a href="mailto:pradeep@scoticdesigns.com">pradeep@scoticdesigns.com</a></p>
          </div>
          
          <div className="footer-section">
            <h3>USA</h3>
            <p>New York:<br />
            267, 5th Ave, No.506<br />
            10016 New York<br /><br />
            Charleston:<br />
            1549, Folly Rd, Charleston<br />
            SC 29412. USA<br />
            <a href="mailto:eric@scoticdesigns.com">eric@scoticdesigns.com</a></p>
          </div>
          
          <div className="footer-section">
            <h3>UK</h3>
            <p>18-22, Church Street<br />
            Lutterworth<br />
            Leicestershire, LE17 4AW<br />
            UK<br />
            <a href="mailto:david@scoticdesigns.com">David@scoticdesigns.com</a></p>
          </div>

          <div className="footer-section">
            <h3>UAE</h3>
            <p>Scotic Designs FZ LLC<br />
            FDRK2427, Compass Building,<br />
            Al Shohada Road,<br />
            Al Hamra Industrial Zone-FZ<br />
            Ras Al Khaimah<br />
            UAE<br />
            <a href="mailto:raza@scoticdesigns.com">zajjad@scoticdesigns.com</a></p>
          </div>

          <div className="footer-section">
            <h3>China</h3>
            <p>Shaoxing Jinhul Building 15B<br />
            Wendu Road No.161,<br />
            Kenqiao District<br />
            Zhejiang Province<br />
            312000 – China<br/>
            <a href="mailto:bjm@scoticdesigns.com ">bjm@scoticdesigns.com</a></p>
          </div>
          
          <div className="footer-section">
            <h3>Bangladesh</h3>
            <p>Scotic Designs<br />
            Door # 502, House # 9/F<br />
            Road # 13, Sector-03,<br />
            Uttara, Dhaka-1230<br />
            Bangladesh<br />
            Tel: +8801745476149<br />
            <a href="mailto:biju@scoticdesigns.com ">biju@scoticdesigns.com </a></p>
          </div>
        </div>
        <div className='PP'>
        Copyright © 2024 Scotic Designs.
        All Rights Reserved.
        </div>
      </footer>
    </div>
  );
}

export default Homepage;


//  <section className="pie-charts" ref={pieChartsRef}>
//{isPieChartsVisible && <PieChart />} 
//</section>
// <h2 className="section-title">Shortest Turnaround for Concept Samples</h2>